import userModel from '@/models/user';
import localeModel from '@/models/locale';
import { BANK_DATA, BASE_DATA } from '@/constants/profileSettings';
import { MAIN_SIDE_MENU_ITEMS_LIST, PERSONAL_MENU_ITEMS_LIST } from '@/constants/common';
import {
  USER_CURRENT_LOCATION_ID,
  USER_ROLE_KEY,
  USER_CURRENT_ROLE,
  USER_CURRENT_LOCALE,
} from '@/constants/auth';
import { getCookie, setCookie } from '@/helpers/request';

const profileSettingsSplitter = (acc, currItem, settings) => {
  if (settings[currItem.id]) {
    return {
      ...acc,
      [currItem.id]: {
        ...currItem,
        ...settings[currItem.id],
        value: settings[currItem.id].value || '',
      },
    };
  }

  return acc;
};

const checkForAccess = ({ currentRole, existsMain, existsPeriphery }) => {
  const menuList = MAIN_SIDE_MENU_ITEMS_LIST[currentRole];

  return menuList.map((item) => {
    if (item.subMenuItems) {
      return {
        ...item,
        subMenuItems: item.subMenuItems.map((innerItem) => (
          innerItem.id === 'income-outcome-rooms' && !existsMain
        )
        || (innerItem.id === 'income-outcome-periphery' && !existsPeriphery)
          ? { ...innerItem, inactive: true } : { ...innerItem }),
      };
    }
    return {
      ...item,
    };
  });
};

export default {
  namespaced: true,
  state: () => ({
    isCaptchaPassed: false,
    isAuthenticated: false,
    locations: [],
    units: [],
    unitsByCurrentLocation: [],
    currentLocation: null,
    profileSettings: {
      name: '',
      avatar: '',
      contract: {
        number: '',
        date: '',
      },
      base: {},
      bank: {},
    },
    unitsByLocation: [],
    faqData: [],
    sideMenuItems: [],
    personalMenuItems: [],
    isDiadoc: false,
    roles: [],
    currentRole: null,
    isInvestor: false,
    isLender: false,
    currentLocale: '',
    allLocales: [],
    managerData: {
      name: 'Анастасия',
      phone: '+79633238710',
      preparedPhone: '+7 963 323-87-10',
      email: 'client.service@dewis.ltd ',
    },
  }),
  mutations: {
    'set:is:captcha-passed': function (state, value) {
      state.isCaptchaPassed = value;
    },
    'set:is:authenticated': function (state, value) {
      state.isAuthenticated = value;
    },
    'set:roles': function (state, value) {
      state.roles = value;
    },
    'set:current:role': function (state, value) {
      state.currentRole = value;
      localStorage.setItem(USER_CURRENT_ROLE, value);
      state.isInvestor = state.currentRole === 'INVESTOR';
      state.isLender = state.currentRole === 'LENDER';
    },
    'set:menus': function (state, { sideMenu, personalMenu }) {
      state.sideMenuItems = sideMenu;
      state.personalMenuItems = personalMenu;
    },
    'set:locations': function (state, value) {
      const preparedLocations = value.reduce((acc, item) => {
        if (item.roleCode === state.currentRole) {
          return item.locations;
        }
        return acc;
      }, []);

      if (state.isInvestor) {
        preparedLocations.push({
          id: 666,
          name: 'Новая локация',
          soon: true,
        });
      }
      state.locations = preparedLocations;
    },
    'set:locales:all': function (state, locales = []) {
      state.allLocales = locales.map((locale) => locale.toLowerCase());
    },
    'set:units': function (state, value) {
      state.units = value;
    },
    'set:units:by:current:location': function (state, value) {
      state.unitsByCurrentLocation = value;
    },
    'set:unitsByLocations': function (state, value) {
      state.unitsByLocation = value;
    },
    'set:faqData': function (state, value) {
      state.faqData = value.length
        ? value.map((item) => ({ ...item, id: Math.ceil(Math.random() * 1000000) }))
        : [];
    },
    'set:current:location': function (state, value) {
      state.currentLocation = value;
      localStorage.setItem(USER_CURRENT_LOCATION_ID, value.id);
    },
    'set:current:lang': function (state, value = '') {
      state.currentLocale = value.toLowerCase();
      setCookie(USER_CURRENT_LOCALE, value.toUpperCase());
    },
    'set:profileSettings': function (state, settings) {
      state.profileSettings.name = settings.name.value;
      state.profileSettings.avatar = settings.avatar.value || '';
      state.profileSettings.contract.number = settings.contractNumber.value || '';
      Object.keys(settings).forEach((key) => {
        Object.assign(state.profileSettings, { [key]: settings[key] });
      });
      state.isDiadoc = settings.useDiadoc || false;
    },
    'update:profileSettings': function (state, settings) {
      const updatedToState = Object.keys(settings).reduce((acc, currKey) => ({
        ...acc,
        [currKey]: {
          value: settings[currKey],
          editable: state.profileSettings[currKey].editable,
        },
      }), {});
      Object.assign(state.profileSettings, updatedToState);
    },
  },
  actions: {
    async passCaptcha({ commit }, captchaResponse) {
      const resp = await userModel.captchaVerify(captchaResponse);
      if (resp.data.success) {
        commit('set:is:captcha-passed', true);
      } else {
        return false;
      }

      return resp;
    },

    async trySignUp(context, payload) {
      return userModel.signUp(payload);
    },

    async setPassword(context, payload = {}) {
      return userModel.setPassword(
        { link: payload.link, password: payload.password },
        payload.origin,
      );
    },

    async recoverPassword(context, payload) {
      return userModel.passwordRecover(payload);
    },

    async changePassword(context, payload) {
      return userModel.passwordChange(payload);
    },

    setLocale({ commit }, locale = '') {
      if (getCookie(USER_CURRENT_LOCALE) !== locale.toLowerCase()) {
        localeModel.setUserLocale(locale.toUpperCase());
      }
      commit('set:current:lang', locale);
    },

    async getAllLocales({ commit }) {
      const resp = await localeModel.getAllLocales();
      if (resp) {
        commit('set:locales:all', resp);
      }
    },

    async initUserLocale({ commit, dispatch, state }) {
      await dispatch('getAllLocales');
      if (state.isAuthenticated) {
        const userLocale = await localeModel.getUserLocale();
        dispatch('setLocale', userLocale);
      } else {
        commit('set:current:lang', getCookie(USER_CURRENT_LOCALE) || 'ru');
      }
    },

    async login({ commit, state, dispatch }, payload) {
      const resp = await userModel.login(payload);
      if (resp.ok) {
        commit('set:is:authenticated', true);
        commit('set:roles', localStorage.getItem(USER_ROLE_KEY) && localStorage.getItem(USER_ROLE_KEY).length
          ? JSON.parse(localStorage.getItem(USER_ROLE_KEY))
          : []);
        commit('set:current:role', state.roles && state.roles.length ? state.roles[0] : null);
        dispatch('setLocale', getCookie(USER_CURRENT_LOCALE)
          && getCookie(USER_CURRENT_LOCALE).length
          ? getCookie(USER_CURRENT_LOCALE)
          : 'ru');
      }
      return resp;
    },

    async logout({ commit }) {
      userModel.logout();
      commit('set:is:authenticated', false);
    },

    async getUserLocations({ commit }) {
      const resp = await userModel.getUserLocations();
      if (resp.ok) {
        commit('set:locations', resp.data);
        return resp.body;
      }
      return null;
    },

    async getUserUnits({ commit }) {
      const resp = await userModel.getUserUnits();
      if (resp.ok) {
        commit('set:units', resp.data);
      }
    },

    async getUserUnitsFullInfo({ commit }) {
      const resp = await userModel.getUserUnitsFullInfo();
      if (resp.ok && resp.data.locations && resp.data.locations.length) {
        commit('set:unitsByLocations', resp.data.locations);
      }
    },

    async getUserProfileSettings({ commit }) {
      const resp = await userModel.getUserProfileSettings();
      if (resp.ok) {
        commit('set:profileSettings', resp.data);
      }
      return null;
    },

    async getFaq({ commit }) {
      const resp = await userModel.getFaq();
      if (resp && resp.length) {
        commit('set:faqData', resp);
      }
      return null;
    },

    async updateUserProfileSettings({ commit }, settings) {
      if (!settings.length) {
        return;
      }

      const updatedSettings = settings.reduce((acc, currItem) => ({
        ...acc,
        [currItem.id]: currItem.currentValue,
      }), {});

      const resp = await userModel.updateUserProfileSettings(updatedSettings);
      if (resp.ok) {
        commit('update:profileSettings', updatedSettings);
      }
    },

    async initUsersLocations({
      commit, dispatch, state,
    }) {
      commit('set:roles', localStorage.getItem(USER_ROLE_KEY) && localStorage.getItem(USER_ROLE_KEY).length
        ? JSON.parse(localStorage.getItem(USER_ROLE_KEY))
        : []);

      if (localStorage.getItem(USER_CURRENT_ROLE)
        && localStorage.getItem(USER_CURRENT_ROLE).length) {
        commit('set:current:role', localStorage.getItem(USER_CURRENT_ROLE));
      } else {
        commit('set:current:role', state.roles && state.roles.length ? state.roles[0] : null);
      }

      await dispatch('getUserLocations');
      if (!state.locations && !state.locations.length) {
        return;
      }

      const locationIdIncluds = state.locations.some(
        (item) => item.id === Number(localStorage.getItem(USER_CURRENT_LOCATION_ID)),
      );

      dispatch('setCurrentLocation', localStorage.getItem(USER_CURRENT_LOCATION_ID) === null || !locationIdIncluds
        ? state.locations[0].id
        : JSON.parse(localStorage.getItem(USER_CURRENT_LOCATION_ID)));
      await dispatch('getUserUnits');
      commit('set:units:by:current:location', state.units
        .filter((unit) => unit.locationId === state.currentLocation.id));
    },

    async initSideMenuItems({ state, commit }) {
      const sideMenuItems = checkForAccess({
        currentRole: state.currentRole,
        ...state.currentLocation,
      });
      commit('set:menus', {
        sideMenu: sideMenuItems,
        personalMenu: PERSONAL_MENU_ITEMS_LIST[state.roles.length > 1 ? 'INVESTOR' : state.roles[0]],
      });
    },

    setCurrentLocation({ commit, state }, id) {
      commit('set:current:location', state.locations.find((location) => location.id === id) || null);
    },

    setCurrentRole({ commit, dispatch }, role) {
      commit('set:current:role', role);
      dispatch('initUsersLocations');
      dispatch('initSideMenuItems');
    },
  },
  getters: {
    baseProfileSettings(state) {
      return BASE_DATA
        .reduce(
          (acc, currentItem) => profileSettingsSplitter(
            acc,
            currentItem,
            state.profileSettings,
          ),
          {},
        );
    },
    bankProfileSettings(state) {
      return BANK_DATA
        .reduce(
          (acc, currentItem) => profileSettingsSplitter(
            acc,
            currentItem,
            state.profileSettings,
          ),
          {},
        );
    },
  },
};
