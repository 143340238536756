<template>
  <div
    class="main-layout"
    :class="{
      'main-layout__authorized': blank,
    }"
  >
    <template v-if="hasUnsignDoc">
      <main-notification url="/documents">
        <template #content>
          {{ $t('needSign') }}
          <svg style="margin-left: 4px; transform: rotate(270deg);" width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.49998 6C5.30284 6 5.10572 5.92797 4.95542 5.78422L0.225656
            1.25832C-0.0752187 0.970413 -0.0752187 0.503627 0.225656 0.21584C0.526409 -0.0719468
            1.01413 -0.0719468 1.31503 0.21584L5.49998 4.22061L9.68496 0.21598C9.98584 -0.0718069
            10.4735 -0.0718069 10.7742 0.21598C11.0753 0.503767 11.0753 0.970553 10.7742
            1.25846L6.04455 5.78436C5.89417 5.92814 5.69705 6 5.49998 6Z" fill="#EB5757"/>
          </svg>
        </template>
      </main-notification>
    </template>
    <main-header
      :class="{'main-layout__header--logo-hidden': hideLogoText}"
    >
      <template #navigation>
        <slot name="navigation"></slot>
      </template>
    </main-header>
    <section class="main-layout__content">
      <slot name="content"></slot>
    </section>
    <main-footer/>
  </div>
  <div class="main-layout__mobile-stub">
    <slot name="mobile-stub"></slot>
  </div>
</template>

<script>
import MainHeader from '@/components/MainHeader/MainHeader.vue';
import MainFooter from '@/components/MainFooter/MainFooter.vue';
import MainNotification from '@/ui-kit/MainNotification/MainNotification.vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';
import { computed, watch } from 'vue';
import { NOT_ALLOWED_PATHS_FOR_LENDERS } from '@/constants/common';
import { useI18n } from 'vue-i18n';

export default {
  name: 'main-layout',
  components: {
    MainNotification,
    MainFooter,
    MainHeader,
  },
  props: {
    blank: {
      type: Boolean,
      default: false,
    },
    hideLogoText: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const router = useRouter();
    const route = useRoute();
    const hasUnsignDoc = computed(() => store.state.documents.hasUnsignDocuments);
    const { locale } = useI18n();

    store.subscribe((mutation, state) => {
      if (
        !state.user.isInvestor
        && NOT_ALLOWED_PATHS_FOR_LENDERS.some((item) => route.path.includes(item))
      ) {
        router.push('/dashboard/');
      }
    });

    watch(
      () => store.state.user.currentLocale,
      (newLocale) => {
        locale.value = newLocale;
      },
      { immediate: true },
    );

    store.dispatch('documents/initDocuments');
    return {
      hasUnsignDoc,
    };
  },
};
</script>

<style lang="scss">
.main-layout {
  display: flex;
  width: 100vw;
  height: 100vh;
  flex-direction: column;
  position: relative;

  &__content {
    flex-grow: 2;
  }

  &__blank {
    .main-header {
      border-bottom: none;
    }
  }

  &__mobile-stub {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    display: none;
  }
}

@media (max-width: 980px) {
  .main-layout {
    &__mobile-stub {
      display: flex;

      &:empty {
        display: none;
      }
    }
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .main-layout {
    height: auto;

    &__authorized {
      .main-header {
        &__location-mobile, &__burger-menu {
          display: none;
        }
      }
    }

    &__header--logo-hidden {
      .main-header__logo-text-img {
        display: none;
      }
    }
  }
}
</style>
