import bookingModel from '@/models/booking';
import {
  getTimeline, prepareToYYYYMMDD, updateBookingPeripheryData, updateBookingUnitsData,
} from '@/helpers/datesAndTime';
import { ONE_DAY, ONE_WEEK } from '@/constants/dates';
import { OCCUPATION_INFO_STATUS_LIST, CLEANING_UNITS_STATUS } from '@/constants/booking';

export default {
  namespaced: true,
  state: () => ({
    currentDate: null,
    dateStart: null,
    dateEnd: null,
    bookingUnitsData: [],
    bookingPeripheryData: [],
    timeline: {
      flat: [],
      groupedByMonth: [],
    },
    currentLocation: '',
    isLoading: false,
    countOfUnits: null,
    loadToday: {
      countOfUnits: null,
      percentOfUnits: null,
    },
    income: {
      thisMonth: null,
      lastMonth: null,
    },
    guestsToday: OCCUPATION_INFO_STATUS_LIST,
    unitsStatus: CLEANING_UNITS_STATUS,
    averageRoomPrice: {
      thisMonth: null,
      lastMonth: null,
    },
    isFirstLoadingFinished: false,
    isLoadingStatistics: false,
    filters: {
      source: null,
    },
    bookingLocations: [],
    peripheryLocations: [],
    currentBookingLocation: null,
  }),
  mutations: {
    'set:currentLocation': function (state, value) {
      state.currentLocation = value;
    },
    'set:bookingUnitsData': function (state, value) {
      state.bookingUnitsData = value;
    },
    'set:bookingPeripheryData': function (state, value) {
      state.bookingPeripheryData = value;
    },
    'set:bookingDates': function (state, { currentDate, dateStart, dateEnd }) {
      state.currentDate = currentDate;
      state.dateStart = dateStart;
      state.dateEnd = dateEnd;
    },
    'set:booking:locations': function (state, { bookingLocations = null, peripheryLocations = null }) {
      state.bookingLocations = bookingLocations;
      state.peripheryLocations = peripheryLocations;
    },
    'set:timeline': function (state, { months, flatDays }) {
      state.timeline.flat = flatDays;
      state.timeline.groupedByMonth = months;
    },
    'set:current:booking:location': function (state, id) {
      state.currentBookingLocation = id;
    },
    'set:isLoadingStatistics': function (state, value) {
      state.isLoadingStatistics = value;
    },
    'set:isLoading': function (state, value) {
      state.isLoading = value;
    },
    'set:isFirstLoadingFinished': function (state, value) {
      state.isFirstLoadingFinished = value;
    },
    'set:filters': function (state, { name, value }) {
      state.filters[name] = value;
    },
    'set:booking:statistic': function (state, statistic) {
      state.countOfUnits = statistic.countOfUnits || null;
      state.loadToday = statistic.loadToday || {
        countOfUnits: null,
        percentOfUnits: null,
      };
      state.income = statistic.income || {
        thisMonth: null,
        lastMonth: null,
      };
      state.guestsToday = statistic.guestsToday
        ? state.guestsToday.map((status) => ({
          ...status,
          amount: statistic.guestsToday[status.id],
        }))
        : OCCUPATION_INFO_STATUS_LIST;
      state.unitsStatus = statistic.unitsStatus && Object.keys(statistic.unitsStatus).length
        ? state.unitsStatus.map((status) => ({
          ...status,
          amount: statistic.unitsStatus[status.id],
        }))
        : CLEANING_UNITS_STATUS;
      state.averageRoomPrice = statistic.averageRoomPrice || {
        thisMonth: null,
        lastMonth: null,
      };
    },
  },
  actions: {
    async initBookingsTable({ commit, dispatch }) {
      const currentDate = new Date().setHours(11);
      const dateStart = new Date(currentDate - ONE_WEEK).getTime();
      const dateEnd = new Date(currentDate + ONE_WEEK * 4).getTime();
      commit('set:isLoading', true);
      commit('set:bookingDates', { dateStart, dateEnd });
      commit('set:timeline', getTimeline(dateStart, dateEnd));
      await dispatch('getBookingUnitsData');
      commit('set:isFirstLoadingFinished', true);
      commit('set:isLoading', false);
    },
    async initPeripheryBookingsTable({ commit, dispatch }) {
      const currentDate = new Date();
      const dateStart = currentDate.setHours(0);
      const dateEnd = new Date(dateStart + ONE_DAY * 2).setHours(12);
      commit('set:isLoading', true);
      commit('set:bookingDates', { currentDate, dateStart, dateEnd });
      commit('set:timeline', getTimeline(dateStart, dateEnd));
      await dispatch('getBookingPeripheryData');
      commit('set:isFirstLoadingFinished', true);
      commit('set:isLoading', false);
    },
    async getBookingUnitsData({ commit, state, rootState }) {
      const resp = await bookingModel
        .getUnitsBooking({
          startDate: prepareToYYYYMMDD(state.dateStart),
          endDate: prepareToYYYYMMDD(state.dateEnd),
          location: rootState.user.isInvestor
            ? rootState.user.currentLocation.id
            : state.currentBookingLocation,
        });
      if (resp.ok) {
        const updatedUnitsData = updateBookingUnitsData(resp.data.groups, state.timeline.flat);
        commit('set:bookingUnitsData', updatedUnitsData);
      } else {
        console.error('[BOOKING STORE]: getting units booking is not ok');
      }
    },
    async getBookingPeripheryData({ commit, state, rootState }) {
      const resp = await bookingModel
        .getPeripheryBooking({
          startDate: prepareToYYYYMMDD(state.dateStart),
          endDate: prepareToYYYYMMDD(state.dateEnd),
          location: rootState.user.isInvestor
            ? rootState.user.currentLocation.id
            : state.currentBookingLocation,
        });
      if (resp.ok) {
        const updatedPeripheryData = resp.data.groups
          ? updateBookingPeripheryData(
            resp.data.groups,
            state.timeline.flat,
          )
          : [];
        commit('set:bookingPeripheryData', updatedPeripheryData);
      } else {
        console.error('[BOOKING STORE]: getting periphery booking is not ok');
      }
    },
    async updateBooking({ commit, dispatch, state }, isNextDates) {
      commit('set:isLoading', true);
      const dateStart = isNextDates ? state.dateStart : state.dateStart - ONE_WEEK;
      const dateEnd = isNextDates ? state.dateEnd + ONE_WEEK * 4 : state.dateEnd;
      commit('set:bookingDates', { dateStart, dateEnd });
      commit('set:timeline', getTimeline(dateStart, dateEnd));
      await dispatch('getBookingUnitsData');
      commit('set:isLoading', false);
    },
    async updatePeripheryBooking({ commit, dispatch, state }, options) {
      commit('set:isLoading', true);
      let dateStart = state.dateStart;
      let dateEnd = state.dateEnd;
      if (options) {
        dateStart = options.isNext ? state.dateStart : state.dateStart - ONE_DAY * 3;
        dateEnd = options.isNext ? state.dateEnd + ONE_DAY * 3 : state.dateEnd;
      }
      commit('set:bookingDates', { dateStart, dateEnd });
      commit('set:timeline', getTimeline(dateStart, dateEnd));
      await dispatch('getBookingPeripheryData');
      commit('set:isLoading', false);
    },

    async getBookingStatistics({ commit, rootState }) {
      commit('set:isLoadingStatistics', true);
      const resp = await bookingModel.getBookingStatistics(rootState.user.currentLocation.id);
      if (resp.ok) {
        commit('set:booking:statistic', resp.data); // временное скрываем инфу
        commit('set:isLoadingStatistics', false);
      }
    },

    async getBookingLocations({ commit }) {
      const resp = await bookingModel.getBookingLocations();

      if (resp.ok && resp.data && resp.data.length) {
        commit('set:booking:locations', resp.data.reduce((acc, location) => {
          const newAcc = { ...acc };
          if (location.existsMain) {
            newAcc.bookingLocations.push({
              name: location.name,
              id: location.id,
            });
          }
          if (location.existsPeriphery) {
            newAcc.peripheryLocations.push({
              name: location.name,
              id: location.id,
            });
          }

          return newAcc;
        }, {
          peripheryLocations: [],
          bookingLocations: [],
        }));
      }
    },

    async getBookingPeripheryStatistics({ commit, rootState }) {
      commit('set:isLoadingStatistics', true);
      const resp = await bookingModel
        .getBookingPeripheryStatistics(rootState.user.currentLocation.id);
      if (resp.ok) {
        commit('set:booking:statistic', resp.data); // временное скрываем инфу
        commit('set:isLoadingStatistics', false);
      }
    },

    setFilter({ commit }, { name, value }) {
      commit('set:filters', { name, value });
    },

    setCurrentBookingLocation({ commit }, id = null) {
      commit('set:current:booking:location', id);
    },

    updateDatesStarEnd({ commit }, { date, isHourly }) {
      commit('set:bookingDates', {
        dateStart: isHourly ? new Date(date).setHours(0) : date,
        dateEnd: isHourly ? new Date(date).setHours(0) + ONE_DAY * 3 : date + ONE_WEEK * 4,
      });
    },
  },
};
